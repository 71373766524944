import type { CategoryData } from "@xxl/frontend-api";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../hooks/useXxlMediaQuery";
import { BreadcrumbsDesktop } from "./Breadcrumbs.desktop";
import { BreadcrumbsMobile } from "./Breadcrumbs.mobile";

export type Breadcrumb = CategoryData & {
  /** translated label */
  name: string;
  /** SEO-friendly url */
  url: string;
};

export type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
  homeUrl: string;
  /** Whether to include the last breadcrumb (category name) as h1 on mobile or not */
  includeH1?: boolean;
  /** If set to true - component will always show at least the "home" breadcrumb */
  shouldAlwaysShowHomeCrumb?: boolean;
  shouldUseNextLink?: boolean;
  isElevate?: boolean;
};

export const LAPTOP_BREAKPOINT = "LaptopMediaQuery";

export const Breadcrumbs = ({
  breadcrumbs,
  homeUrl,
  includeH1 = false,
  shouldAlwaysShowHomeCrumb = false,
  shouldUseNextLink,
  isElevate = false,
}: BreadcrumbsProps) => {
  const isLaptopSize = useXxlMediaQuery(LAPTOP_BREAKPOINT);
  const { t } = useTranslations();

  const homeCrumb = {
    name: t("breadcrumbs.home"),
    url: "/",
  };

  if (breadcrumbs.length === 0) {
    if (!shouldAlwaysShowHomeCrumb) {
      return null;
    }
    return (
      <BreadcrumbsDesktop
        breadcrumbs={[homeCrumb]}
        homeUrl={homeUrl}
        shouldUseNextLink={shouldUseNextLink}
      />
    );
  }

  const breadcrumbsToDisplay = [
    homeCrumb,
    ...breadcrumbs.slice(
      1,
      isElevate || includeH1 ? breadcrumbs.length : breadcrumbs.length - 1
    ),
  ];

  if (isLaptopSize) {
    return (
      <BreadcrumbsDesktop
        breadcrumbs={breadcrumbsToDisplay}
        homeUrl={homeUrl}
        shouldUseNextLink={shouldUseNextLink}
      />
    );
  }

  const firstBreadcrumb = breadcrumbsToDisplay.at(-2);
  const lastBreadcrumb = breadcrumbsToDisplay.at(-1);

  if (firstBreadcrumb === undefined || lastBreadcrumb === undefined) {
    return null;
  }

  return (
    <BreadcrumbsMobile
      includeH1={includeH1}
      firstBreadcrumb={firstBreadcrumb}
      lastBreadcrumb={lastBreadcrumb}
      homeUrl={homeUrl}
      shouldUseNextLink={shouldUseNextLink}
    />
  );
};
